import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import MaintenanceBanner from "./components/MaintenanceBanner";
import Theme from "./Theme";

const Login = lazy(() => import("./pages/login/Login"));
const IntermediateForgotPassword = lazy(() =>
  import(
    "./pages/password/intermediateForgotPassword/IntermediateForgotPassword"
  )
);
const ForgotPasswordParent = lazy(() =>
  import("./pages/password/forgotPasswordParent/ForgotPasswordParent")
);
const ResetPassword = lazy(() => import("./pages/resetPassword"));
const NewKoobits = lazy(() => import("./pages/intermediate/NewKoobits"));
const StudentHwLoginPage = lazy(() =>
  import("./pages/login/StudentHwLoginPage")
);
const ExternalLogin = lazy(() => import("./pages/externalLogin/index"));

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      pathname.includes("forgot-password") ||
      pathname.includes("reset-password")
    ) {
      document.title = "KooBits - Reset Password";
    } else {
      document.title = "KooBits - Login";
    }
  }, [pathname]);

  return (
    <Theme>
      <div className="App">
        {process.env.REACT_APP_SHOW_MAINTENANCE_BANNER === "true" && (
          <MaintenanceBanner />
        )}
        <Switch>
          <Suspense fallback={<div />}>
            <Route exact path="/" component={Login} />
            <Route exact path="/beta" component={NewKoobits} />
            <Route
              exact
              path="/forgot-password"
              component={IntermediateForgotPassword}
            />
            <Route path="/reset-password/parent" component={ResetPassword} />
            <Route
              exact
              path="/forgot-password/parent"
              component={ForgotPasswordParent}
            />
            <Route
              exact
              path={`/studenthomework/:homeworkID/:studentID`}
              component={StudentHwLoginPage}
            />
            <Route
              path={`/studenthomework/:homeworkID`}
              component={StudentHwLoginPage}
            />
            <Route exact path="/external-login">
              <ExternalLogin />
            </Route>
          </Suspense>
        </Switch>
      </div>
    </Theme>
  );
}

export default App;
