import React from "react";
import { ThemeProvider } from "styled-components";

export const rwd = {
  mobileS: 374,
  mobileM: 424,
  mobileL: 480,
  mobile: 767,
  tabletS: 1023,
  tablet: 1223,
  laptop: 1439,
  desktop: 2559,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={{ rwd }}>{children}</ThemeProvider>
);
export default Theme;
