import { createGlobalStyle } from "styled-components";
import LinotteRegularWoff2 from "assets/fonts/Linotte/linotte.woff2";
import LinotteRegularWoff from "assets/fonts/Linotte/linotte.woff";
import LinotteSemiboldWoff2 from "assets/fonts/Linotte/linotte-semibold.woff2";
import LinotteSemiboldWoff from "assets/fonts/Linotte/linotte-semibold.woff";
import LinotteLightWoff2 from "assets/fonts/Linotte/linotte-light.woff2";
import LinotteLightWoff from "assets/fonts/Linotte/linotte-light.woff";
import LinotteBoldWoff2 from "assets/fonts/Linotte/linotte-bold.woff2";
import LinotteBoldWoff from "assets/fonts/Linotte/linotte-bold.woff";
import MuliTtf from "assets/fonts/Muli/Muli.ttf";
import MuliBTtf from "assets/fonts/Muli/MuliB.ttf";
import MuliSTtf from "assets/fonts/Muli/MuliS.ttf";
import MuliXB from "assets/fonts/Muli/Muli-ExtraBold.ttf";
import MuliBlack from "assets/fonts/Muli/Muli-Black.ttf";
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Linotte';
    src: url(${LinotteRegularWoff2}) format('woff2'), url(${LinotteRegularWoff}) format('woff');
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'LinotteSemiBold';
    src: url(${LinotteSemiboldWoff2}) format('woff2'), url(${LinotteSemiboldWoff}) format('woff');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'LinotteLight';
    src: url(${LinotteLightWoff2}) format('woff2'), url(${LinotteLightWoff}) format('woff');
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'LinotteBold';
    src: url(${LinotteBoldWoff2}) format('woff2'), url(${LinotteBoldWoff}) format('woff');
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Muli';
    src: local('Muli'), url(${MuliTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'MuliBold';
    src: local('MuliB'), url(${MuliBTtf}) format('truetype');
    font-weight: 700;
    font-style: bold;
  }
  
  @font-face {
    font-family: 'MuliSemiBold';
    src: local('MuliS'), url(${MuliSTtf}) format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'MuliExtraBold';
    src: local('Muli-ExtraBold'), url(${MuliXB}) format('truetype');
    font-weight: 800;
  }
  @font-face {
    font-family: 'MuliBlack';
    src: local('Muli-Black'), url(${MuliBlack}) format('truetype');
    font-weight: 900;
  }

html,
body {
  margin: 0;
  font-family: "Linotte Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


`;
export default GlobalStyle;
