import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #faeebe;
  border: 1px solid #ecc36b;
  padding: 5px 10px;
  text-align: center;
  color: black;
`;

const MaintenanceBanner = () => {
  return (
    <Container>
      <p>
      Currently, Cloudfare (an external server provider) is having a widespread outage and it is affecting many online services worldwide.<br/>You may experience intermittent disruption if you login or use the KooBits platform.
If you need any further assistance regarding KooBits platform, you can contact us <a href="https://www.koobits.com/contact" rel="noopener noreferrer" target="_blank">here</a> or via <a href="mailto:support@koobits.com">support@koobits.com</a>.
      </p>
    </Container>
  );
};

export default MaintenanceBanner;
